<template>
  <div class="section">
    <v-container class="px-10 py-8 mt-10" fluid>
      <div class="section__header-container">
        <v-row justify="center">
          <v-col xl='6' lg="10" sm="10" cols='12' class='text-center'>
            <h2 class="section__header-title">Pricing that works for You</h2>
            <p>No pre-paid credits or minimum spend. Only pay for the tools you use.</p>
            <v-row justify="center" class='d-flex align-center mt-5'>
              <span class="mt-4 mb-2">Select your monthly SMS usage to get an estimate</span>
            </v-row>
            <v-row justify="center" class="mt-5">
              <v-btn-toggle outlined v-model="selectedCategory" style="word-break: break-word; height: auto; display: flex; flex-wrap: wrap; text-align: center; justify-content: center;">
                <v-btn class="btn-primary--outline font-weight-semibold" v-for="(item, index) in menuItems" :key="index">{{item.name}}</v-btn>
              </v-btn-toggle>
            </v-row>
          </v-col>
        </v-row>
        <v-row justify="center mt-7">
          <v-col xl="6" lg="7" cols="12">
            <v-card>
              <v-simple-table class="pricing-table">
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <td class="text-body-1 font-weight-semibold">SMS</td>
                      <td>
                        <span>{{ selectedItem.smsPrice}}</span>
                      </td>
                      <td>
                        <span>Per unit</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="text-body-1 font-weight-semibold">Agents</td>
                      <td>
                        <span>{{ selectedItem.agents }}</span>
                      </td>
                      <td>
                        <p>Additional $15 per user</p>
                        <p class="mt-2">Only users logged into Yabbr Chat that month will incur a fee.</p>
                      </td>
                    </tr>
                    <tr>
                      <td class="text-body-1 font-weight-semibold">Mobile Numbers</td>
                      <td>
                        <span>{{ selectedItem.virtual }}</span>
                      </td>
                      <td>
                        <span>Per number per month</span>
                      </td>
                    </tr>
                    <tr class="no-border">
                      <td colspan="100" class="text-body-1 font-weight-semibold">Voice Call Routing</td>
                    </tr>
                    <tr class="no-border">
                      <td>AU Landline</td>
                      <td>
                        <span>{{ selectedItem.auLandLine }}</span>
                      </td>
                      <td>
                        <span>Per minute</span>
                      </td>
                    </tr>
                    <tr class="no-border">
                      <td>AU Mobile</td>
                      <td>
                        <span>{{ selectedItem.auMobile }}</span>
                      </td>
                      <td>
                        <span>Per minute</span>
                      </td>
                    </tr>
                    <tr class="no-border">
                      <td>AU FLRN</td>
                      <td>
                        <span>{{ selectedItem.auFLRN }}</span>
                      </td>
                      <td>
                        <span>Per minute</span>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="100">
                        <p class="price-text mt-4">Prices shown in AUD. All prices are estimates only.</p>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>
          </v-col>
        </v-row>
        <v-row justify="center mt-7">
          <v-col xl="6" lg="10" sm="10" cols="12" class="text-center">
            <h3 class="mt-7 mb-3">Not sure on your usage?</h3>
            <p>Contact us to receive a quote tailored to your business.</p>
            <v-btn
              depressed
              large
              outlined
              :ripple="false"
              class="mt-4 btn-primary--outline btn-primary rounded-lg"
              @click="openChat">
              Get in touch
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Pricing",
  data: () => ({
    selectedCategory: 0,
    menuItems: [
      { name: '100 - 5000',  smsPrice: '3c', agents: '2 users free', virtual: '$20', auLandLine: '4c', auMobile: '8c', auFLRN: '8c', value: 0},
      { name: '5000 - 100,000', smsPrice: '4c', agents: '4 users free', virtual: '$40', auLandLine: '5c', auMobile: '9c', auFLRN: '9c', value: 1},
      { name: '100,000 +', smsPrice: '5c', agents: '10 users free', virtual: '$60', auLandLine: '6c', auMobile: '10c', auFLRN: '10c', value: 2},
      // { name: '300,000 +', smsPrice: '6c', agents: '20 users free', virtual: '$80', auLandLine: '7c', auMobile: '11c', auFLRN: '11c', value: 3},
    ],
  }),
  computed: {
    selectedItem () {
      return this.menuItems.find((item) => item.value === this.selectedCategory);
    },
  },
  methods: {
    openChat() {
      if (window.yabbr) {
        window.openYabbrWidget();

      } else {
        console.error('Yabbr is not loaded yet.');
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.pricing-table {
  padding: 28px;
  .no-border td {
    border: none !important;
    border-color: none !important;
  }

  tbody {
    tr {
      vertical-align: top;
    }

    tr:hover {
      background-color: transparent !important;
    }

    tr > td {
      padding: 10px;
    }

    tr > td:last-child {
      width: 40%;
    }

    td > p {
      margin: 0;
      font-size: 1rem;
    }

    td > span {
      font-size: 1rem;
    }

    td {
      font-size: 1rem;
      padding: 0.75rem !important;
    }

    td > p.price-text {
      font-size: 0.875rem;
      opacity: 0.6;
    }
  }
}
</style>
