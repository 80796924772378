<template>
  <div class="section">
    <v-container class="px-10 pt-7" fluid>
      <v-row justify="center">
        <v-col xl="6" lg="6" md="6" cols="12">
          <h2 class="section__header-title">Multi-medium, multi-agent</h2>
          <div>
            <h3>All in one Chat</h3>
            <p>Yabbr Chat seamlessly moves between Voice and Text mediums.</p>
            <h3>Translate</h3>
            <p>Automated Text Translation in 40+ Languages.</p>
            <h3>Chat Routing</h3>
            <p>Routing Rules optimise your inbound traffic, letting your team focus on the hard stuff.</p>
            <h3>Reach out to your audience</h3>
            <p>Easily compose custom messages on a grand scale with our Email and SMS Campaigns.</p>
            <p>Feed your Campaign responses directly into Yabbr Chat.</p>
          </div>
        </v-col>
        <v-col xl="4" lg="4" md="6" col="12" class="my-auto">
          <div class="d-flex justify-center mb-4 d-lg my-auto">
            <v-img
              src="../assets/img/chat-widget.png"
              max-width="400px"
              alt="Yabbr Chat"
              contain />
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'OmniWidget',
}
</script>

<style lang="scss">

</style>
