<template>
  <div class="tier-wrapper">
    <template v-for="(tierRow, rowIndex) in this.organisedTiers">
      <v-row :key="rowIndex" justify="space-between" align="stretch" class="align-stretch" no-gutters>
        <template v-for="(tier, colIndex) in tierRow">
          <v-col :key="colIndex" class="tier flex-grow-1 mb-md-0 mb-sm-10 mb-10" md="4" sm="12" cols="12">
            <v-card flat tile fluid class="card rounded-0 text-center py-6 px-3 fill-height">
              <v-card-text>
                <v-card-title class="justify-center text-h5 font-weight-medium text--primary pb-md-1 pb-sm-3">{{  tier.name  }}</v-card-title>
                <p class="tier-caption text-md-subtitle-2 font-weight-regular text-sm-body-1 mx-md-3 mx-sm-8 blue-grey--text text--darken-1 pt-4" style="height: 3.5em;">{{ tier.description }}</p>
                <v-divider class="mt-8 mx-12" />
                <div v-if="tier.price > 0" class="price mt-8">
                  <span class="price__dollars text-h3 text--primary p-0 m-0 ">${{ convertedPrice(tier.price).dollars}}
                    <span class="price__cents text-h6" v-if="convertedPrice(tier.price).cents">.{{ convertedPrice(tier.price).cents }}</span>
                  </span>
                  <span class="price__currency text-caption blue-grey--text text--darken-1">AUD</span>
                  <span class="price__per text-caption blue-grey--text text--darken-1">/mo.</span>
                </div>
                <div v-if="tier.price === 0" class="price mt-8">
                  <span class="price__dollars text-h3 text--primary p-0 m-0 ">Free</span>
                </div>
                <v-card-actions class="d-flex flex-column align-center mt-4">
                  <v-btn
                    class="white--text btn-primary rounded-lg px-6"
                    depressed
                    :ripple="false"
                    large
                    :href="tier.action.link"
                    @click="handle_function_call(tier.action.onClick)">
                    {{ tier.action.label }}
                  </v-btn>
                  <span v-show="tier.price !== 0" class="text-subtitle-2 font-weight-regular pt-2 blue-grey--text text--darken-1">Pay per user per month</span>
                </v-card-actions>
                <p
                  class="text-body-1 text-center pb-0 mb-1 blue-grey--text text--darken-1"
                  v-if="tier.features.length"
                  :class="tier.price !== 0 ? 'mt-8' : 'mt-15'">Features:</p>
                <v-col class="payment-list d-flex justify-center align-center" v-if="tier.features.length">
                  <v-list max-width="270">
                    <template v-for="(feature, featIndex) in tier.features">
                      <v-list-item list-item class="payment-list-item my-0 py-0" :key="featIndex">
                        <v-list-item-icon class="pr-0 mr-3 my-2">
                          <v-icon color="green">mdi-check-circle</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content class="text-left py-2 text-wrap">
                          <v-list-item-title class="text-wrap">{{ feature }}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-list>
                </v-col>
              </v-card-text>
            </v-card>
          </v-col>
        </template>
      </v-row>
    </template>
  </div>
</template>

<script>
export default {
  name:'PaymentTiers',
  props: [
    'tiers',
    'cols'
  ],
  beforeMount() {
    this.organisedTiers = this.organiseTiers();
  },
  data: () => ({
    organisedTiers: [],
  }),
  methods: {
    organiseTiers: function() {
      // 12 is the maximum amount of columns we can have in a row
      var pointsPerRow = Math.floor(12 / this.cols);
      var rowAmount = Math.round(this.tiers.length / pointsPerRow);
      var organisedTiers = [];
      var organisedTiersIndex = 0;
      for (var i = 0; i < rowAmount; i++) {
        var nextIndex = organisedTiersIndex + pointsPerRow;
        if (nextIndex >= this.tiers.length) {
          organisedTiers.push(this.tiers.slice(organisedTiersIndex, this.tiers.length));
          break;
        } else {
          organisedTiers.push(this.tiers.slice(organisedTiersIndex, nextIndex));
          organisedTiersIndex = nextIndex;
        }
      }
      return organisedTiers;
    },
    convertedPrice: function(originalPrice) {
      let price = { dollars: '', cents: '' };
      price.dollars = Math.trunc(originalPrice).toString();
      price.cents = (originalPrice - Math.trunc(originalPrice)) == 0 ? '' : (originalPrice - Math.trunc(originalPrice)).toFixed(2).toString().split(".")[1];
      return price;
    },
    openChat() {
      if (window.yabbr) {
        window.openYabbrWidget();
      } else {
        console.error('Yabbr is not loaded yet.');
      }
    },
    handle_function_call(function_name) {
      this[function_name]()
    },
  },
};
</script>

<style lang="scss" scoped>
.tier-wrapper {
  @include atMedium {
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 12px 28px 0px rgb(49 56 61 / 10%);
  }
}

.tier {
  box-shadow: 0px 12px 28px 0px rgb(49 56 61 / 10%);

  @include atMedium {
    border-right:2px solid #F5F7FA;

    &:last-child{
      border-right: none;
    }
  }

  .v-card{
    border-radius: 0.625rem;

    @include atMedium {
      border-radius: 0 !important;
    }
  }

  hr {
    border-color: rgba(0,0,0,0.05);
  }
  .price {
    position:relative;

    &__dollars{
      display: inline-flex;
      letter-spacing: -0.05em !important;
    }

    &__cents{
      letter-spacing: -0.01em;
      margin-top: -.05rem;
      padding-left: .15rem;
    }

    &__currency {
      line-height: 1.6;
      letter-spacing: -0.01em;
      position:absolute;
      top: 5px;
      padding-left: .3rem;
    }

    &__per{
      line-height: 1.6;
      letter-spacing: -0.01em;
      position:relative;
      top: -1px;
      padding-left: 0.3rem;
    }
  }

  .tier-caption {
    line-height: 1.2;
  }

  .payment-list {
    .v-list {
      width: 100%;
    }
  }
  .payment-list-item {
    &.v-list-item {
      min-height: auto !important;
    }
    .v-list-item__title {
      line-height: 1.4;
    }
  }
}
</style>
