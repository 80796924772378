<template>
  <div class="hero-banner">
    <div class="hero-banner__content flex-column d-flex align-center justify-center my-0 mx-auto">
      <div class="hero-banner__text text-center mt-lg-10 mt-xs-6 mt-sm-8 mt-md-8 px-2">
        <h1 class="text-center">Your new engagement platform</h1>
        <body class="text-center mt-10">SMS and Voice Broadcast for Australian Business</body>
        <v-btn
          class="btn-primary white--text rounded-lg mt-8"
          elevation="0"
          :ripple="false"
          href="https://go.yabbr.io/#/signup"
          large>
          Sign up today
        </v-btn>
      </div>
      <div class="hero-banner__image-wrapper d-flex justify-center px-6 px-md-0 mt-10">
        <v-img
          class="hero-banner__image px-sm-6"
          src="../assets/img/brochure-banner.png"
          max-width="640"
          alt="Yabbr Platform"
          contain />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Hero'
}
</script>

<style lang="scss">
.hero-banner {
  height: 100vh;
  position:relative;

  &:before {
    content: '';
    position:absolute;
    width: 100%;
    // background: #102a43;
    background: #091531;
    left: 0;
    bottom: 0;
    z-index: 0;
    height: 30%;
  }

  // @include atMedium {
  //   &:before {
  //     height: 45%;
  //   }
  // }

  // @include atLarge {
  //   &:before {
  //     height: 45%;
  //   }
  // }

  &__content {
    position:relative;
    // max-width: 860px;
    height: 100%;
    padding: 60px 1rem 0 1rem;

    @include atSmall {
      padding: 20px 1rem 0 1rem;
    }

    @include atMedium {
      padding: 20px 1rem 0 1rem;
    }

    h1 {
      font-size: 2.5rem;
      line-height: 1.4;

      @include atSmall {
        font-size: 2.25rem;
      }
      @include atMedium {
        font-size: 2.5rem;
      }
      @include atExtraLarge {
        font-size: 3rem;
      }
    }

    body {
      font-size: 1.25rem;
    }
  }

  &__image-wrapper {
    width: 100%;
  }

  &__image {
    width: 100%;
  }
}
</style>
