<template>
  <div class="section section-center" id="contact">
    <v-container class="px-10 pt-7" fluid>
      <div class="section__header-container">
        <v-row justify="center">
          <v-col xl="5" lg="5" md="6" sm="10" cols="12" class="text-left">
            <h2 class="section__header-title">Curious? Get in touch</h2>
            <p class="pb-4">Get in touch to book a product demo, or get a quote. Our team would be happy to talk through our services and find the perfect solution for your needs.</p>
            <v-btn
              depressed
              large
              :ripple="false"
              class="white--text btn-primary rounded-lg" @click="openChat">
              Chat live with our team
            </v-btn>
          </v-col>
          <v-col xl="5" lg="5" md="5" cols="12" class="text-center mt-6">
            <div class="sms-body-content">
              Call or SMS
            </div>
            <div class="call-sms">
              <h3 class="number-title">0480 0 YABBR</h3>
              <div class="call-sms__number-subtitle text-gray">92227</div>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'ContactUs',
  methods: {
    openChat() {
      if (window.yabbr) {
        window.openYabbrWidget();
      } else {
        console.error('Yabbr is not loaded yet.');
      }
    },
  },
};
</script>

<style lang="scss">
h3.number-title {
  font-size: 2rem;
  color: #091531;
  font-weight: 500;
  letter-spacing: 4px;

  @include atSmall {
    letter-spacing: 0;
  }

  @include atLarge {
    font-size: 3rem;
  }
}

.sms-body-content {
  font-size: 20px;
  margin-bottom: 14px;
}

.call-sms {
  display: inline-block;
  position: relative;

  &__number-subtitle {
    // position: absolute;
    // right: -24px;
    // bottom: -26px;
    font-size: 1.25rem;
    letter-spacing: 16px;
    text-align: right;
  }

  @include atSmall {
    letter-spacing: 16px;
    right: -16px;
  }

  @include atMedium {
    letter-spacing: 4px;
  }
}
</style>
