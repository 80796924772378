<template>
  <div class="">
    <div class="testimonials dark-blue">
      <v-container class="px-10 py-10" fluid>
        <div class="section__header-container">
          <v-row justify="center">
            <v-col xl="6" lg="8" md="10" cols="12">
              <h2 class="section__header-title text-center white--text">Customer experiences</h2>
              <v-carousel
                v-model="model"
                cycle
                hide-delimiter-background
                :show-arrows="false"
                interval=8500
                class="testimonials__carousel">
                <v-carousel-item
                  v-for="(testimonial, i) in testimonials"
                  :key="i">
                  <!-- <v-icon class="testimonials__quotation">mdi-format-quote-open</v-icon> -->
                  <div class="d-flex pt-4 px-16 mb-6 quote white--text">
                    "{{ testimonial.quote }}"
                  </div>
                  <div class="d-flex pb-16 px-16 mb-6 author white--text">
                    - {{ testimonial.author }}
                  </div>
                </v-carousel-item>
              </v-carousel>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Testimonials',
  data: () => ({
    model: 0,
    testimonials: [
      {
        quote: 'Communicating with our members can be challenging. Yabbr helps us to do this in new ways which are interactive and personal.',
        author: 'Jake Araullo, United Workers',
      },
      {
        quote: 'We were looking for a way to reach a greater audience with our market research programs. Yabbr’s Survey platform is cost-effective, easy to manage, and intuitive to use.',
        author: 'Mikkel Brezhnev, uComms',
      },
      {
        quote: 'Yabbr has revolutionised the call centre industry, giving customers a voice! Live translation of over 40 languages has helped us break down barriers with our customer base.',
        author: 'Dawn Donelly, Panthera Finance',
      },
      {
        quote: 'Introducing a combined 2-way SMS, Web Chat, and Email platform to our business saw a 52% rise in response rates.',
        author: 'Katie McRae, Bill Chaser',
      },
    ],
  }),
}
</script>

<style lang="scss">
.testimonials {
  width: 100%;
  padding-top: 1rem;

  &__quotation {
    font-size: 5rem !important;
    color: #fff !important;
  }

  &__carousel {
    height: 13em !important;

    @include atExtraSmall {
      height: 20em !important;
    }

    @include atSmall {
      height: 16em !important;
    }


    @include atMedium {
      height: 13em !important;
    }

    @include atLarge {
      height: 13em !important;
    }

    @include atLarge {
      height: 13em !important;
    }

    @include atExtraLarge {
      height: 13em !important;
    }


    & .v-carousel__controls__item .v-icon {
      opacity: 1;
    }

    & .v-carousel__controls__item.v-btn {
      color: #BAD6ED;
      opacity: 0.25;
    }

    & .v-carousel__controls__item.v-btn.v-btn--active {
      color: #fff;
      opacity: 1;
    }

    & .v-carousel__controls__item.v-btn.v-btn--active:before {
      opacity: 0;
    }

    & .v-carousel__controls__item.v-btn:hover {
      color: #fff;
    }

    & .v-carousel__controls__item.v-btn:hover:before {
      opacity: 0;
    }
  }


  .quote {
    font-size: 1.1rem;
    padding: 1em 3em 0 !important;

    @include atExtraSmall {
      padding: 0 !important;
    }

    @include atSmall {
      padding: 1em 3em 0 !important;
    }

    @include atMedium {
      font-size: 1.2rem;
    }

    @include atLarge {
      font-size: 1.4rem;
    }

    @include atLarge {
      font-size: 1.4rem;
    }

    @include atExtraLarge {
      font-size: 1.4rem;
    }
  }


  .author {
    font-weight: 500;
    padding: 0em 4em !important;
    @include atExtraSmall {
      padding: 0 !important;
    }

    @include atSmall {
      padding: 0em 4em !important;
    }
  }

}
</style>
