<template>
  <div class="section capabilities gray">
    <v-container class="px-10 pt-7" fluid>
      <div class="section__header-container">
        <v-row justify="center">
          <v-col xl="6" lg="7" sm="10" cols="12">
            <h2 class="section__header-title text-center">A unified communications platform that's built to scale</h2>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col xl="6" lg="7" cols="12">
            <v-card>
              <v-simple-table class="capabilities__table">
                <template v-slot:default>
                  <tbody>
                    <tr v-for="item in features" :key="item.name">
                      <td>{{ item.name }}</td>
                      <td class="text-center">
                        <v-icon>mdi-check</v-icon>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'Capabilities',
  data: () => ({
    features: [
      {
        name: 'Aggregation of all chat mediums',
      },
      {
        name: 'Self-cure majority of inbound communications',
      },
      {
        name: '2-way response enhances automation and AI-Bot cure rate'
      },
      {
        name: 'Post integration surveys for Satisfation or NPS scores'
      },
      {
        name: 'Auto-replies based on inbound message',
      },
      {
        name: 'Hold and calendar booking functionality',
      },
      {
        name: 'Reference Client website for auto-replies',
      },
      {
        name: 'Enhanced privacy and anonymity for Customers',
      },
      {
        name: 'Multiple Groups / Agent Skill Queues / Routing Options / Word Based Rules'
      }
    ],
  }),
}
</script>

<style lang="scss">
// .section--capabilities {
//   table {
//     padding: 30px;
//
//     tr {
//       border-color: #ccc;
//     }
//
//     td {
//       font-size: 1rem !important;
//       padding: 10px 0 10px 0 !important;
//
//       @include atLarge {
//         font-size: 1.2rem;
//       }
//
//       .v-icon {
//         color: #16bd78;
//       }
//     }
//   }
// }
.capabilities {
  // background-color: white;
  // box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  // padding-left: 3rem;
  // overflow: hidden;
  table {
    width: 100%;
    padding: 28px;
    tbody {
      tr:hover {
        background-color: transparent !important;
      }

      tr {
        &:first-of-type td {
          padding-top: 2.5rem;
        }

        &:last-of-type td {
          padding-bottom: 2.5rem;
        }

        // &:not(:first-of-type) {
        //   border-top: 1px solid #f8f8f8;
        // }

        td {
          border-color: #e7e7e7 !important;
          font-size: 1rem !important;
          padding: 0.75rem !important;

          &:first-of-type {
            background-color: white;
            box-shadow: inset -22px 0px 28px -35px rgba(0, 0, 0, 0.15);
            padding-right: 10rem;
            width: 85%;
          }

          &:not(:first-of-type) {
            background-color: white;
            width: 24%;

            div {
              display: flex;
              width: 100%;
            }
          }
        }
      }
    }
    .v-icon {
      color: #16bd78;
    }
  }
}
</style>
