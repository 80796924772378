<template>
  <div>
    <template v-for="(sellingPointsRow, rowIndex) in this.organisedPoints">
      <v-row :key="rowIndex" align="stretch" class="justify-center mb-4">
        <template v-for="(point, colIndex) in sellingPointsRow">
          <v-col :cols="cols" :key="colIndex">
            <v-card :class="colClass" class="pa-3 fill-height">
              <div :class="iconClass">
                <v-icon :size="vIconSize">{{ point.icon }}</v-icon>
              </div>
              <v-card-title class="item__header">
                <h3>{{ point.title }}</h3>
              </v-card-title>
              <v-card-text class="item__description text--primary">
                <p>{{ point.description }}</p>
              </v-card-text>
            </v-card>
          </v-col>
        </template>
      </v-row>
    </template>
  </div>
</template>

<script>
export default {
  name: 'SellingPoints',
  props: [
    'points',
    'cols',
    'colContainerClass',
    'iconContainerClass',
    'iconSize',
  ],
  data: () => ({
    organisedPoints: [],
    colClass: '',
    iconClass: '',
    vIconSize: 0,
  }),
  beforeMount() {
    this.organisedPoints = this.organisePoints();
    this.colClass = this.colContainerClass ? this.colContainerClass : 'item';
    this.iconClass = this.iconContainerClass ? this.iconContainerClass : 'item__icon';
    this.vIconSize = this.iconSize ? this.iconSize : 40;
  },
  methods: {
    organisePoints: function() {
      // 12 is the maximum amount of columns we can have in a row
      var pointsPerRow = Math.floor(10 / this.cols);
      var rowAmount = Math.round(this.points.length / pointsPerRow);
      var organisedPoints = [];
      var organisedPointsIndex = 0;
      for (var i = 0; i < rowAmount; i++) {
        var nextIndex = organisedPointsIndex + pointsPerRow;
        if (nextIndex >= this.points.length) {
          organisedPoints.push(this.points.slice(organisedPointsIndex, this.points.length));
          break;
        } else {
          organisedPoints.push(this.points.slice(organisedPointsIndex, nextIndex));
          organisedPointsIndex = nextIndex;
        }
      }
      return organisedPoints;
    }
  }
}
</script>