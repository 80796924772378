<template>
  <div class="section grey-card">
    <v-container class="px-10" fluid>
      <v-row justify="center">
        <v-col xl="4" lg="5" md="6" col="12" class="my-auto">
          <div class="enterprise-sms__image-container d-lg my-auto">
            <v-img
              src="../assets/img/send-syntax.png"
              max-width="360px"
              alt="Syntax graphic"
              contain>
            </v-img>
          </div>
        </v-col>
        <v-col xl="5" lg="6" md="6" cols="12">
          <h2 class="section__header-title">API's for reliable messaging</h2>
          <div>
            <v-list-item class="three-line">
              <v-icon color="green" class="mr-2">mdi-check-circle</v-icon>
              <v-list-item-content>
                <v-list-item-title class="text-wrap">Integrate Yabbr into your backend systems using our comprehensive API.</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-icon color="green" class="mr-2">mdi-check-circle</v-icon>
              <v-list-item-content>
                <v-list-item-title class="text-wrap">Developer friendly documentation empowers your team to integrate quickly.</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-icon color="green" class="mr-2">mdi-check-circle</v-icon>
              <v-list-item-content>
                <v-list-item-title class="text-wrap">Fast, reliable delivery across SMS, Email and Voice Calls.</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-btn
              depressed
              large
              outlined
              :ripple="false"
              class="mt-6 btn-primary--outline btn-primary rounded-lg"
              href="https://api.yabbr.io/#/2019-01-23/messages/messages-post"
              target="_blank">
              Read the docs
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'APIDocs',
}
</script>

<style lang="scss">
.grey-card{
  background-color: #F5F7FA !important;
}
.enterprise-sms {
  &__image-container {
    display: flex;
    margin-bottom: 2rem !important;
    justify-content: center;

    @include atMedium {
      justify-content: center;
    }
  }
}
</style>
