<template>
  <div class="section gray">
    <v-container class="px-10 grid-list-md fluid fill-height justify-center">
      <v-row justify="center">
        <v-col xl='6' lg="10" sm="10" cols='12' class='text-center mb-6'>
          <h2 class="section__header-title">Product features</h2>
          <p>Choose which products are right for your team.</p>
        </v-col>
      </v-row>
      <PaymentTiers :tiers="this.tiers" :cols="4" />
    </v-container>
  </div>
</template>

<script>
import PaymentTiers from './PaymentTiers.vue'

export default {
  name: 'Payment',
  components: {
    PaymentTiers
  },
  data: () => ({
    tiers: [
      {
        name: 'Basic',
        description: 'Outbound communications',
        price: 0,
        action: {
          label: 'Sign up today',
          link: 'https://go.yabbr.io/#/signup'
        },
        features: [
          'SMS',
          'Campaigns',
          'Surveys',
        ]
      },
      {
        name: 'Engagement',
        description: 'All two-way communications',
        price: 32,
        action: {
          label: 'Get in touch',
          onClick: "openChat",
        },
        features: [
          'Yabbr Chat',
          'Workflows',
        ]
      },
      {
        name: 'CRM',
        description: 'Track customer interactions and engagements',
        price: 240.9,
        action: {
          label: 'Get in touch',
          onClick: "openChat",
        },
        features: [
          'Yabbr CRM',
        ]
      }
    ]
  }),
  
}
</script>

<style lang="scss" scoped>

</style>
