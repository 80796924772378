<template>
  <div class="section mb-0 pb-0">
    <v-container fluid>
      <v-row justify="center">
        <v-col>
          <h2 class="text-center font-weight-regular">Proudly partnered with</h2>
          <div class="d-flex justify-center">
            <v-img
              class="pt-10 mt-8"
              src="../assets/img/equifax-logo.png"
              max-width="300"
              alt="Equifax logo"
              contain />
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'Partner',
}
</script>

<style lang="scss" scoped>
h2 {
  font-size: 1.5rem;
}
</style>
