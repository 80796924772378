<template>
  <v-main>
    <Pricing />
    <Payment />
  </v-main>
</template>

<script>
import Pricing from './Pricing.vue'
import Payment from './Payment.vue'
export default {
  name: 'PricingPage',
  components: {
    Pricing,
    Payment,
  },
}
</script>
