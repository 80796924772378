<template>
  <div class="section dark-blue">
    <v-container class="px-10" fluid>
      <v-row justify="center">
        <v-col xl="4" lg="4" md="6" col="12" class="my-auto">
          <div class="d-flex justify-center mb-4 d-lg my-auto">
            <v-img
              src="../assets/img/number-graph.png"
              max-width="250px"
              alt="BYO Number to Yabbr"
              contain />
          </div>
        </v-col>
        <v-col xl="3" lg="5" md="6" cols="12">
          <h2 class="section__header-title white--text larger-header">Cloud SMS, built for Enterprise</h2>
          <div class="white--text">
            <p class="tagline">23 billion text messages are sent each day worldwide.</p>
            <p>Enable your customers to connect with you the way they prefer, and supercharge SMS in your omni-channel strategy.</p>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'EnterpriseSMS',
}
</script>

<style lang="scss">
.larger-header {
  font-size: 3em;
}

.tagline {
  font-size: 1.5em;
}
</style>
