<template>
  <v-main>
    <Hero />
    <EnterpriseSMS />
    <Products />
    <StreamlineEngagement />
    <APIDocs />
    <OmniWidget />
    <Capabilities />
    <Testimonials />
    <Partner />
    <ContactUs />
  </v-main>
</template>

<script>
import Hero from './Hero.vue'
import OmniWidget from './OmniWidget.vue'
import APIDocs from './APIDocs.vue'
import StreamlineEngagement from './StreamlineEngagement'
import Products from './Products.vue'
import EnterpriseSMS from './EnterpriseSMS.vue'
import Capabilities from './Capabilities.vue'
import Testimonials from './Testimonials.vue'
import Partner from './Partner.vue'
import ContactUs from './ContactUs.vue'

export default {
  name: 'YabbrBrochure',
  components: {
    Hero,
    OmniWidget,
    APIDocs,
    StreamlineEngagement,
    Products,
    EnterpriseSMS,
    Capabilities,
    Testimonials,
    Partner,
    ContactUs,
  },
}
</script>
