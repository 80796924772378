<template>
  <div class="section gray">
    <v-container class="py-7 px-10" fluid>
      <div class="section__header-container">
        <v-row>
          <v-col>
            <h2 class="section__header-title text-center">All-in-one Business Communication</h2>
            <p class="text-center pb-4">Find out how Yabbr can enhance your Business needs.</p>
          </v-col>
        </v-row>
      </div>
      <div class="d-lg-flex d-md-flex d-sm-none d-none section-center">
        <SellingPoints
          :points="this.points"
          cols="3"
          iconContainerClass="item__icon item--background pt-5 pl-2"
          iconSize="60" />
      </div>
      <div class="d-xl-none d-lg-none d-md-none d-sm-flex d-flex">
        <SellingPoints
          :points="this.points"
          cols="10"
          colContainerClass=""
          iconContainerClass="item__icon item--background pt-5 pl-2"
          iconSize="60" />
      </div>
    </v-container>
  </div>
</template>

<script>
import SellingPoints from './SellingPoints.vue'

export default {
  name: 'Products',
  components: {
    SellingPoints
  },
  data: () => ({
    points: [
      {
        icon: '$crm',
        title: 'CRM',
        description: 'Track customer interactions and engagements from Yabbr.',
      },
      {
        icon: '$liveChat',
        title: 'Live Chat',
        description: 'Harness the power of a unified comms platform to engage with your customers - combined with the convenience and personal touch of direct SMS and calls.',
      },
      {
        icon: '$contactTools',
        title: 'One and Two-way Campaigns',
        description: 'Organise events, manage polls, and broadcast at scale.',
      },
      {
        icon: '$platform',
        title: 'Customer Journey Systems',
        description: 'White-labelled portals allow you to create an on-brand experience for your customers - no styling required.',
      },
      {
        icon: '$workflow',
        title: 'Workflows',
        description: "Build a communication strategy that maximises customer engagement, or configure rule-based auto-comms such as follow-ups, NPS Surveys, and team notifications.",
      },
      {
        icon: '$chatBot',
        title: 'Chatbots',
        description: "AI enabled chatbots assist customers with simple tasks, and route conversations using your business rules.",
      },
    ],
  }),
}
</script>

<style lang="scss">
.item {
  &__icon {
    margin-bottom: 1.25rem;
  }

  &__header {
    margin-bottom: 0.5rem;
    color: #091531;
  }

  &--padding {
    padding-right: 10rem;
    @include atExtraLarge {
      padding-right: 2rem;
    }
  }

  &--grey-card {
    border-radius: 0.625rem;
    padding: 1.37rem 1.18rem 1.93rem;
    background-color: #f8f8f8;
  }

  &--background > span {
    padding: 0.8rem;
    background-color: rgba(#D85B15, 0.1);
    border-radius: 10%;
  }
}
</style>
