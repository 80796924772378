<template>
  <div class="section">
    <v-container class="px-10 py-7" fluid>
      <v-row justify="center">
        <v-col xl="6" lg="5" md="5" cols="12" class="d-flex streamline">
          <h2 class="section__header-title large-title"><div class="text-swiper">Streamline</div> customer <br> engagement</h2>
          <div>
          </div>
        </v-col>
        <v-col xl="4" lg="6" md="7" col="12" class="my-auto vertical-divider">
          <div class="d-flex justify-center mb-4 d-lg my-auto">
            <v-img
              src="../assets/img/comms-workflow.png"
              max-width="600px"
              alt="Communication workflow"
              contain />
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'StreamlineEngagement',
}
</script>

<style lang="scss">

.streamline {
  align-items: center;
  justify-content: right;
  padding-right: 10em;
  text-align: left;
  z-index: 2;

  @include atSmall {
    justify-content: center;
    padding-right: 0;
    text-align: center;
  }

  @include atMedium {
    text-align: left;
  }

  @include atLarge {
    align-items: center;
    justify-content: right;
    padding-right: 10em;
    text-align: left;
  }
}

.large-title {
  font-size: 3.5em;
  @include atSmall {
    font-size: 2em;
  }

  @include atMedium {
    font-size: 2.75em;
  }

  @include atLarge {
    font-size: 3.5em;
  }
}

.vertical-divider {
  border-left: 1px solid;
  border-image: linear-gradient(to top, transparent 25%, grey 25%, grey 75%, transparent 75%) 1;
  @include atSmall {
    border: none;
  }
  @include atMedium {
    border-left: 1px solid;
    border-image: linear-gradient(to top, transparent 25%, grey 25%, grey 75%, transparent 75%) 1;
  }
}

.text-swiper::after {
  position: relative;
  display: flex;
  width: 10em;
  height: 10em;
  z-index: 999;
  background-color: blue;
}

$text-loader-color-1: #000000;
$text-loader-color-2: #1582d8;

.text-swiper {
  background: linear-gradient(90deg, $text-loader-color-1 25%, $text-loader-color-2 50%, $text-loader-color-1 75%);
  background-size: 200% 100%;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  animation: text-swipe 4s linear infinite;
}

@keyframes text-swipe {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}
// .animated-workflow {
//   background-image: radial-gradient(red 5%, green 15%, blue 60%);

// }
</style>
